import styled from "styled-components";
import ThumbnailBackground from "../../../images/pentagon.svg";

export const FeatureItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 350px;
  
  & .thumbnail {
    margin-bottom: 20px;
    background-image: url(${ThumbnailBackground});
    background-repeat: no-repeat;
    background-position: bottom center;

    @media only screen and (min-width: 992px) {
      margin-bottom: 30px;
    }
  }
  
  & .feature-content {
    font-size: 14px;
    text-align: center;
    color: #757575;
    
    & .title {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #313541;
      margin-bottom: 10px;
    }
    
    & .excerpt {
      margin-bottom: 20px;
  
      @media only screen and (min-width: 992px) {
        margin-bottom: 30px;
      }
    }
    
    & .learn-more {
      background: #0090F1;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      color: #FFFFFF;
      padding: 8px 20px 10px;
      cursor: pointer;
      display: inline-block;
      
      @media (min-width: 768px) {
        min-height: 40px;
        padding: 10px 30px 13px;
        font-size: 14px;
      }
      
      @media (min-width: 1200px) {
        min-height: 44px;
      }
      
      @media (min-width: 1920px) {
        min-height: 54px;
        padding: 13px 30px 15px;
        font-size: 16px;
      }
    }
  }
`;

