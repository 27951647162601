import styled from "styled-components";

const SectionWrapper = styled.section`
  position: relative;
  padding: 50px 0;

  @media (min-width: 1200px) {
    padding: 100px 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #0090F1 33.85%, rgba(0, 144, 241, 0.6) 78.12%, rgba(0, 144, 241, 0) 100%);
    opacity: 0.08;
  }

  & > * {
    position: relative;
    z-index: 3;
  }

  .blockTitle {
    text-align: center;
    margin-bottom: 63px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      margin-bottom: 33px;
    }

    h2 {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 1;
      letter-spacing: -0.03em;
      color: #09131f;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.4;
      color: #858b91;
      margin: 20px 0 0;
    }
  }
`;

export const StyledOption = styled.span`
  padding: 14px 20px !important;
`
export const FormWrap = styled.div`
  text-align: center;
  max-width: 740px;
  margin: 0 auto;

  .form-field {
    margin-bottom: 20px;

    .error {
      display: block;
      text-align: left;
      color: red;
      margin-top: 4px;
      margin-left: 2px;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
    }
  }
  .styled-select select {
     background: transparent;
     -webkit-appearance: none;
   }
  .styled-select {
    overflow: visible;
    background: #FFF;
  }
  .styled-select select::-ms-expand {
    display: none;
  }

  select.styled-select option {
    padding: 5px 10px;
    color:red;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 14px 20px;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  ::-webkit-input-placeholder {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  ::-moz-placeholder {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  :-ms-input-placeholder {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  :-moz-placeholder {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  .form-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  .form-col {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;
    }

    .error {
      display: block;
      text-align: left;
      color: red;
      margin-top: 4px;
      margin-left: 2px;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
    }
  }
`;

export default SectionWrapper;
