import styled from "styled-components";

export const Section = styled.section`
  margin-bottom: 10px;
  
  @media (min-width: 1200px) {
    margin-bottom: 30px;
  }
`;

export const FeatureHeaderWrapper = styled.div`
  margin-bottom: 40px;
  text-align: center;
  
  @media only screen and (min-width: 1200px) {
    margin-bottom: 70px;
  }
  
  & h5 {
    font-weight: 800;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #0090F1;
  }
  
  h2 {
     font-weight: 800;
     font-size: 22px;
     text-align: center;
     color: #000000;
  
    @media only screen and (min-width: 992px) {
     font-size: 26px;
    }
  
    @media only screen and (min-width: 1200px) {
     font-size: 30px;
    }
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
  
  & .feature-item {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  
    @media only screen and (min-width: 992px) {
      margin-bottom: 30px;
    }
  
    @media only screen and (min-width: 1200px) {
      width: 33.33%;
    }
    
    & .thumbnail img {
        transition: all 0.3s ease-in-out;
    }
    
    &:hover .thumbnail img {
        transform: rotate(15deg);
    }
    
    &:last-child:hover .thumbnail img {
        transform: rotate(-15deg);
    }
  }
`;
