import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Container from "../../common/components/UI/Container";
import Heading from "../../common/components/Heading";
import {FeatureHeaderWrapper, FeatureWrapper, Section} from "./index.style";
import FeatureItem from "./FeatureItem";
import Fade from "react-reveal/Fade";

const Features = ({type}) => {
  const data = useStaticQuery(graphql`
    query {
      hipsterJson {
        features {
          id
          icon
          title
          desc
          link
          url
        }
      }
    }
  `);
  return (
    <Section>
      <Container>
        <FeatureHeaderWrapper>
          <Fade top>
            <Heading as="h5" content={`Crema ${type}`}/>
            <Heading as="h2" content={`Crema ${type} is a great kick-starter`}/>
          </Fade>
        </FeatureHeaderWrapper>
        <FeatureWrapper>
          {data?.hipsterJson?.features?.map((feature) => (
            <div className="feature-item" key={feature.id}>
              <Fade bottom>
                <FeatureItem feature={feature}/>
              </Fade>
            </div>
          ))}
        </FeatureWrapper>
      </Container>
    </Section>
  );
};

export default Features;
