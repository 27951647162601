import React, {useRef, useState} from "react";
import emailjs from "emailjs-com";
import SnackBar from "my-react-snackbar";
import SectionWrapper, {FormWrap} from "./ContactUs.style";
import Reaptcha from 'reaptcha';
import Container from "../../common/components/UI/Container";
import Heading from "../../elements/Heading";
import Box from "../../elements/Box";
import Text from "../../elements/Text";
import Button from "../../elements/Button";

const ContactUs = ({btnStyle}) => {
  const [isShowing, setShow] = useState(false);
  const [isSuccess, setSuccess] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState({name: '', email: '', subject: '', message: '', tech_stack:''});
  const [errors, setErrors] = useState({});
  const [isVerified, setVerified] = useState(false);
  const captcha = useRef(null);


  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    setSubmitting(true)

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name is required.";
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters allowed.";
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email is required.";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf('@');
      let lastDotPos = fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid.";
      }
    }
    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "Subject is required.";
    }

    if (!fields["message"]) {
      formIsValid = false;
      errors["message"] = "Message is required.";
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleChange = (e, name) => {
    console.log("name:", name, e.target.value)
    fields[name] = e.target.value;
    errors[name] = '';
    setFields(fields);
    setErrors(errors);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      emailjs
        .send(
          "service_4823ksr",
          "template_3vlq58q",
          {
            name: fields.name,
            reply_to: fields.email,
            subject: fields.subject,
            message: `User: ${fields.name}\n
              Subject: ${fields.subject}\n
              Tech Stack: ${fields.tech_stack}\n
              Detail: ${fields.message}`,
          },
          "user_PMYldNPmWbjCi9pLqGLGM"
        )
        .then(
          () => {
            setShow(true);
            setSuccess(true);
            setSubmitting(false)
            setTimeout(() => setShow(false), 5000);
            setFields({name: '', email: '', subject: '', message: '', tech_stack:''})
            setErrors({})
            e.target.email.value = "";
            e.target.name.value = "";
            e.target.subject.value = "";
            e.target.message.value = "";
            e.target.tech_stack.value = "";
            e.target.form?.clear();
          },
          () => {
            setSuccess(false);
            setShow(true);
            setSubmitting(false)
            setTimeout(() => setShow(false), 5000);
          })
    } else {
      setSubmitting(false)
    }
  };

  return (
    <SectionWrapper>
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content="To develop your idea with Crema Team."/>
          <Text
            as="p"
            content="Fill in the form with your details and we will revert back to you soon."
          />
        </Box>

        <FormWrap>
          <form onSubmit={onSubmit}>
            <Box className="form-row">
              <Box className="form-col form-field">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  onChange={(e) => handleChange(e, "name")}
                /> <span className="error">{errors["name"]}</span>
              </Box>
              <Box className="form-col form-field">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  onChange={(e) => handleChange(e, "email")}
                /> <span className="error">{errors["email"]}</span>
              </Box>
            </Box>
            <Box className="form-field">
              <input
                className="form-control"
                type="text"
                name="subject"
                placeholder="Subect"
                onChange={(e) => handleChange(e, "subject")}
              /> <span className="error">{errors["subject"]}</span>
            </Box>
            <Box className="form-field styled-select">
              <div className='styled-select'> <select
                  className="form-control select-box"
                  name="tech_stack"
                  placeholder="Select Tech Stack"
                  onChange={(e) => handleChange(e, "tech_stack")}
              >
                <option
                    className="form-select" value="" disabled selected>Select your Tech Stack</option>
                <option
                    className="form-select" value="MUI with CRA">MUI with CRA</option>
                <option
                    className="form-select" value="MUI with Next.js">MUI with Next.js</option>
                <option
                    className="form-select" value="MUI+TypeScript with CRA">MUI+TypeScript with CRA</option>
                <option
                    className="form-select" value="MUI+TypeScript with Next.js">MUI+TypeScript with Next.js</option>
                <option
                    className="form-select" value="And Design  with  CRA">And Design  with  CRA</option>
                <option
                    className="form-select" value="And Design with Next.js">And Design with Next.js</option>
                <option
                    className="form-select" value="And+TypeScript Design with CRA">And+TypeScript Design with CRA</option>
                <option
                    className="form-select" value="And+TypeScript Design with Next.js">And+TypeScript Design with Next.js</option>
                <option
                    className="form-select" value="Other">Other Tech Stack</option>
              </select>
              </div>

            </Box>
            <Box className="form-field">
              <textarea
                className="form-control"
                rows="4"
                cols="4"
                name="message"
                minLength={30}
                placeholder="About your requirement"
                onChange={(e) => handleChange(e, "message")}
              /> <span className="error">{errors["message"]}</span>
            </Box>
            <Box className="form-field">
              <Reaptcha
                  ref={captcha}
                  // state={isVerified}
                  sitekey="6LfaHrYoAAAAAEPUEu4ivrQSpdzaDIMXONWsTXDV"
                  onVerify={() => setVerified(true)}
                  onExpire={() => setVerified(false)}
              />
            </Box>

            <Box className="form-field">
              <Button {...btnStyle} type="submit" isLoading={isSubmitting} disabled={isSubmitting || !isVerified} loaderColor="#333333"
                      title="Submit"/>
            </Box>
          </form>
        </FormWrap>
      </Container>
      <SnackBar
        open={isShowing}
        position='bottom-right'
        message={
          isSuccess
            ? "Thanks for Reaching out to us! We will connect with you shortly."
            : "Sorry! please try again later."
        }
        timeout={50000}
        type={isSuccess ? "success" : "warning"}
        containerStyle={{width: "auto", zIndex: 9999999999999}}
      />
    </SectionWrapper>
  );
};

export default ContactUs;
ContactUs.defaultProps = {
  btnStyle: {
    minWidth: "156px",
    fontSize: ["14px", "16px"],
    fontWeight: "500",
    colors: "primaryWithBg",
    pl: ["30px", "40px"],
    pr: ["30px", "40px"],
    pt: ["14px"],
    pb: ["14px"],
    color: "#fff",
    borderRadius: 30,
    borderColor: "transparent",
    fontWeights: "bold",
  },
};
