import React from 'react';
import PropTypes from "prop-types";
import {FeatureItemWrapper} from "./index.style";

const FeatureItem = ({feature}) => {
  return (
    <FeatureItemWrapper>
      <div className="thumbnail">
        <img src={feature.icon} alt={feature.title}/>
      </div>
      <div className="feature-content">
        <h3 className="title">{feature.title}</h3>
        <p className="excerpt">{feature.desc}</p>
        <a className="learn-more" href={feature.url} target="_blank">{feature.link}</a>
      </div>
    </FeatureItemWrapper>
  );
};

export default FeatureItem;

FeatureItem.propTypes = {
  feature: PropTypes.object,
};